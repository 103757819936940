import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import { gzip } from 'node-gzip';

class ApiService {
	private _instance!: AxiosInstance;
	private cancelToken = axios.CancelToken;
	private cancelSource: CancelTokenSource | null = null;
	private prevCallUri = '';

	private headers = {
		'Content-Type': 'application/json',
	};

	init() {
		const kioskApiUrl = (window as any).config.KioskUrl ?? window.location.origin;
		// const kioskApiUrl = 'https://kiosk.dev.singenuity.com';
		console.log('Using', kioskApiUrl, 'as API base');
		this._instance = axios.create({
			baseURL: `${kioskApiUrl}/api/`,
			timeout: 20000,
			headers: this.headers,
			withCredentials: false,
		});
	}

	async post(url: string, data: unknown) {
		console.debug(`POST ${url}() request`, data);
		// const zip = await gzip(JSON.stringify(data)).then((z) => {
		// 	return z;
		// });
		return this._instance
			.post(url, data, { cancelToken: this.cancelSource?.token, headers: { 'Content-Type': 'application/json' } })
			.then((response) => {
				console.debug(`POST ${url}() response`, response.data);
				return response.data;
			})
			.catch((error) => {
				if (axios.isCancel(error)) {
					console.debug(`POST ${url} was cancelled.`);
					// throw ErrorCode.CLIENT_CLOSED_REQUEST;
				}
				// else this.throwError(error);
			})
			.finally(() => (this.cancelSource = null));
	}
	async externalPost(url: string, data: unknown) {
		console.debug(`POST ${url}() request`, JSON.stringify(data));
		const zip = await gzip(JSON.stringify(data)).then((z) => {
			return z;
		});
		return axios
			.post(url, zip, { cancelToken: this.cancelSource?.token, headers: { 'Content-encoding': 'gzip', 'Content-Type': 'application/json' } })
			.then((response) => {
				console.debug(`POST ${url}() response`, response.data);
				return response.data;
			})
			.catch((error) => {
				if (axios.isCancel(error)) {
					console.debug(`POST ${url} was cancelled.`);
					// throw ErrorCode.CLIENT_CLOSED_REQUEST;
				}
				// else this.throwError(error);
			})
			.finally(() => (this.cancelSource = null));
	}

	async get(url: string, data: unknown): Promise<any> {
		if (url == this.prevCallUri && this.cancelSource) this.cancelSource.cancel(); // cancel if same URI
		this.cancelSource = this.cancelToken.source();
		this.prevCallUri = url;

		console.debug(`GET ${url}() request`);
		return this._instance
			.get(url, { params: data, cancelToken: this.cancelSource?.token })
			.then((response) => {
				console.debug(`GET ${url}() response (${response.status})`, response.data);
				return response.data;
			})
			.catch((error) => {
				console.log('error', error.response.status, error.response);
				if (axios.isCancel(error)) {
					console.debug(`GET ${url} was cancelled.`);
					// throw ErrorCode.CLIENT_CLOSED_REQUEST;
				} else if (error.response.status === 409) {
					throw new Error('Conflict');
				} else console.error(error);
				// else this.throwError(error);
			})
			.finally(() => (this.cancelSource = null));
	}

	async externalGet(url: string, data: unknown): Promise<any> {
		return axios.get(url, { params: data, headers: this.headers }).then((response) => {
			return response.data;
		});
	}
	async googleGet(url: string, data: unknown): Promise<any> {
		return axios.get(url, { params: data, headers: { 'Content-Type': 'application/json' } }).then((response) => {
			return response.data;
		});
	}
}

export const apiService = new ApiService();
